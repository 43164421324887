<template>
  <v-main class="nav-layout" :class="isDisableScroll">
    <v-app-bar app elevation="2" :clipped-left="true" color="#37526f">
      <v-toolbar-title class="logo-text">DOM</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form class="search-form" @submit.prevent="doSearch">
        <v-text-field
          v-if="routeMeta.hasSearch"
          v-model="searchInput"
          label="Search"
          hide-details="auto"
          solo
        ></v-text-field>
      </v-form>
      <v-spacer></v-spacer>
      <template v-if="routeMeta.hasModeToggle">
        <v-btn-toggle
          class="mr-2"
          v-model="currentMode"
          rounded
          mandatory
          @change="onToggleMode"
        >
          <v-btn active-class="active-mode" small> Counts </v-btn>
          <v-btn active-class="active-mode" small> Engagements </v-btn>
        </v-btn-toggle>
      </template>
      <v-menu
        left
        bottom
        offset-y
        nudge-bottom="10"
        nudge-right="5"
        max-width="250"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" text>
            <v-avatar size="36" class="mr-1">
              <img :src="brandImage" alt="Brand logo" />
            </v-avatar>
            <span class="mr-1">{{ userInfo.username }}</span>
            <v-icon>
              {{ userMenuOpen ? 'fa-angle-up' : 'fa-angle-down' }}
            </v-icon>
          </v-btn>
        </template>
        <v-list class="pt-0">
          <v-list-item class="py-2 message-quota">
            <div class="quota-text">
              This month messages: <br />
              <strong class="pl-1">{{ totalUsage | numeral }}</strong>
              <strong v-if="summary.quota && summary.quota > 0">
                / {{ summary.quota | numeral }}
              </strong>
            </div>
          </v-list-item>
          <v-list-item link @click="toggleChangePassword">
            <v-list-item-title>Change Password </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      app
      permanent
      width="220"
      :clipped="true"
      :expand-on-hover="isMobileScreen"
    >
      <v-list class="nav-list pt-0" dense>
        <section v-for="item in menuItem" :key="item.name">
          <v-list-group
            class="group-item-override"
            v-if="item.children"
            v-model="item.open"
            :prepend-icon="item.icon + ' fa-fw'"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="subItem in item.children"
              :key="subItem.name"
              :to="subItem.to"
              exact
              link
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }} fa-fw</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ subItem.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else link :key="item.name" :to="item.to" exact>
            <v-list-item-icon>
              <v-icon>{{ item.icon }} fa-fw</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </section>
      </v-list>
    </v-navigation-drawer>
    <v-btn
      class="filter-btn"
      color="white"
      v-if="routeMeta.hasFilter"
      @click="openFilterMenu"
    >
      <v-icon small class="mr-2" :color="usingFilter ? 'green' : '#ddd'">
        fa-circle
      </v-icon>
      Filter
    </v-btn>
    <v-dialog v-model="showChangePassDialog" max-width="400" persistent>
      <v-card>
        <v-card-title>Change password</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              ref="oldPass"
              autocomplete="current-password"
              name="current-password"
              label="Old password"
              v-model="oldPasswordInput"
              :rules="[rules.required, rules.isOldPassword]"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              @input="validatePass(true)"
              required
            ></v-text-field>
            <v-text-field
              ref="newPass"
              autocomplete="new-password"
              name="new-password"
              label="New password"
              v-model="passwordInput"
              :rules="[rules.required, rules.securePassword]"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              @input="validatePass"
              required
            ></v-text-field>
            <v-text-field
              ref="confirmPass"
              autocomplete="new-password"
              name="confirm-password"
              label="Confirm new password"
              v-model="confirmPasswordInput"
              :rules="[rules.required, rules.samePassword]"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              @click:append="show3 = !show3"
              @input="validatePass"
              required
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onChangePassword(false)"
            tabindex="0"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onChangePassword(true)"
            :disabled="disableSavePass"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackBar"
      timeout="2000"
      top
      right
      color="success"
    >
      Password changed successfully.
    </v-snackbar>
    <v-snackbar v-model="errorSnackBar" timeout="2000" top right color="error">
      {{ errorSnackMessage }}
    </v-snackbar>
    <vue-toast-group top right timeout="2000" />
    <slot></slot>
  </v-main>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import api from '@/services/api';

const securePassRegExp = new RegExp(
  '((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32})+$'
);
export default {
  name: 'NavLayout',
  data() {
    return {
      drawer: true,
      mini: true,
      defaultMenuItem: [
        { name: 'Home', to: { name: 'Home' }, icon: 'fa-home' },
        {
          name: 'Sentiment',
          to: { name: 'Sentiment' },
          icon: 'fa-bar-chart-o',
        },
        {
          name: 'Messages',
          icon: 'fa-comments',
          children: [
            {
              name: 'Live Feed',
              to: { name: 'LiveFeed' },
              icon: 'fa-newspaper-o',
            },
            { name: 'Highlight', to: { name: 'Highlight' }, icon: 'fa-star' },
            { name: 'Search', to: { name: 'Search' }, icon: 'fa-search' },
          ],
          open: true,
        },
        { name: 'Location', to: { name: 'Location' }, icon: 'fa-map-marker' },
        { name: 'Influencer', to: { name: 'Influencer' }, icon: 'fa-users' },
        { name: 'Export', to: { name: 'Export' }, icon: 'fa-download' },
        { name: 'Setting', to: { name: 'Setting' }, icon: 'fa-cog' },
      ],
      searchInput: '',
      currentMode: 1,
      brandImage: '',
      userMenuOpen: false,
      showChangePassDialog: false,
      oldPasswordInput: '',
      passwordInput: '',
      confirmPasswordInput: '',
      oldPassError: false,
      rules: {
        required: (value) => !!value || 'Required.',
        securePassword: (value) =>
          securePassRegExp.test(value) ||
          'Password must contain at least 8 lowercase and upper case alphabet, along with digit characters.',
        samePassword: (value) =>
          value === this.passwordInput || 'Confirm password not match',
        isOldPassword: () => !this.oldPassError || 'Old password is not valid.',
      },
      disableSavePass: true,
      successSnackBar: false,
      errorSnackBar: false,
      errorSnackMessage: '',
      show1: false,
      show2: false,
      show3: false,
    };
  },
  created() {
    this.brandImage =
      'https://cdn.ask-dom.com/img/logo/' + this.userInfo.brand + '.png';
  },
  methods: {
    ...mapActions({
      setFilterOpen: 'filter/setFilterOpen',
      setKeywordPhrase: 'filter/setKeywordPhrase',
      toggleEngageMode: 'filter/toggleEngageMode',
    }),
    openFilterMenu() {
      this.setFilterOpen(true);
    },
    doSearch() {
      console.log(this.searchInput);
      this.setKeywordPhrase(this.searchInput);
    },
    onToggleMode() {
      this.toggleEngageMode(!!this.currentMode);
    },
    logout() {
      window.location = '/logout';
    },
    toggleChangePassword(state) {
      this.showChangePassDialog = state;
    },
    async onChangePassword(state) {
      if (state) {
        // Assume that already validate
        const result = await api
          .changeUserPassword({
            'old-password': this.oldPasswordInput,
            'new-password': this.passwordInput,
          })
          .catch((e) => {
            const m = api.extractErrorMessage(e);
            if (m === 'Old password is invalid.') {
              this.oldPassError = true;
              this.$refs.oldPass.validate();
            }
            if (m === 'OK') {
              this.successSnackBar = true;
              return;
            }
            this.errorSnackMessage = m;
            this.errorSnackBar = true;
          });
        if (result) {
          this.successSnackBar = true;
        } else {
          return;
        }
        // Result check -- if error is invalid old password...
      }
      // On complete reset form;
      this.passwordInput = '';
      this.confirmPasswordInput = '';
      this.showChangePassDialog = false;
      this.disableSavePass = true;
    },
    validatePass(updateOldPass) {
      if (updateOldPass) this.oldPassError = false;
      const val =
        this.$refs.oldPass.validate() &&
        this.$refs.newPass.validate() &&
        this.$refs.confirmPass.validate();
      this.disableSavePass = !val;
    },
  },
  computed: {
    ...mapState({
      filterOpen: (state) => state.filter.filterOpen,
      usingFilter: (state) => state.filter.usingFilter,
      userInfo: (state) => state.account.userInfo,
      summary: (state) => state.account.summary,
      totalUsage: (state) => state.account.totalUsage,
    }),
    ...mapGetters({
      userRole: 'account/userRole',
      accessRights: 'account/accessRights',
    }),
    isDisableScroll() {
      if (this.filterOpen) {
        return 'disable-scroll';
      }
      return '';
    },
    isMobileScreen() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint === 'xs' || breakpoint === 'sm';
    },
    menuItem() {
      if (this.userRole && this.userRole === 'feedOnly') {
        return [
          {
            name: 'Messages',
            icon: 'fa-comments',
            children: [
              {
                name: 'Live Feed',
                to: { name: 'LiveFeed' },
                icon: 'fa-newspaper-o',
              },
              { name: 'Highlight', url: 'highlight', icon: 'fa-star' },
              { name: 'Search', url: 'search', icon: 'fa-search' },
            ],
            open: true,
          },
        ];
      }
      // Default menu
      return this.defaultMenuItem;
    },
    routeMeta() {
      if (this.$route.meta) {
        return this.$route.meta;
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-layout {
  &.disable-scroll {
    height: 100vh;
    overflow: hidden;
  }
  .logo-text {
    color: #fff;
  }
  .nav-list {
    .v-list-item,
    .v-list-item__title,
    .v-icon {
      text-transform: uppercase;
      font-weight: 500;
      color: #333;
    }
    .v-list-item--active {
      font-weight: 600;
      background-color: #69a3c5;
      color: #fff;
      .v-list-item__title,
      .v-icon {
        color: #fff;
      }
    }
    .v-icon {
      color: #333;
    }
  }
  .filter-btn {
    position: fixed;
    top: 72px;
    right: 14px;
    z-index: 1;
  }
  .search-form {
    display: flex;
    flex: 1 1 auto;
  }
  .active-mode {
    background-color: #69a3c5;
    color: #fff;
  }
}
</style>
